import styled from "styled-components";

export const Container = styled.div`
	opacity: ${props => props.opacity}
`;

export const Nav = styled.nav``;

export const HR = styled.hr`
	background-color: #CCC;
	height: 1px;
	opacity: 0.6;
	width: 100%;
`;

export const SearchBox = styled.input`
	background-color: #000 !important;
	color: #F7F7F7 !important;
	font-size: 14px;
	padding: 6px 8px;
	border-width: 1px;
	border-style: solid;
	border-color: ${props => props.error ? 'red' : 'black'};
	margin: 0;
`;

export const Circle = styled.svg`
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  
  & .path {
    stroke: #00FF00;
    stroke-linecap: round;
  }
`;
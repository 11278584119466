import React, { Component } from "react";
import { Container, Nav } from "./styled-components";
import config from "./config";
import Dropdown from "react-dropdown";
import UserImg from "./assets/img/user-image.png";
import 'react-dropdown/style.css';
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import '@fortawesome/fontawesome-free/css/all.css';
import Base64 from 'base-64';
import Dashboard from './dashboard';
import CustomerSearch from './customer-search';



const API_URL = 'https://arm-pilot.gamco.es/api/prediction/';
// const API_URL = 'http://localhost:8080/api/prediction';
const RESULTS_LIMIT_DROPDOWN = [
  { value: 1000, 'label': '1000' },
  { value: 5000, 'label': '5000' },
  { value: 100000, 'label': '10000' },
  { value: 0, 'label': 'All' }
];
const RELEASE_ID = 5;
const DROPDOWN_FUNCTIONALITY_OPTIONS = ['Summary', 'Search'];
const RESULTS_PER_PAGE = 1000;
const DEFAULT_PAGE_LIMIT = 1;

class App extends Component {
  constructor() {
    super();
    this.state = {
      dashboardContainerOpacity: 1,
      dataIsLoading: true,
      evaluation: { model: {} },
      pageLimit: DEFAULT_PAGE_LIMIT,
      predictions: [],
      currentPage: 1,
      customerPrediction: '',
      maxRecords: RESULTS_PER_PAGE,
      selectedFunctionality: 'Summary',
      predictionLimit: '5',
      showSearch: false,
      totalCustomers: 0,
      totalElements: 0
    };
    this.dashboard = React.createRef();
    this.changePageLimit = this.changePageLimit.bind(this);
    this.changeFunctionality = this.changeFunctionality.bind(this);
    this.keydownEvent = this.keydownEvent.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    document.addEventListener("keydown", this.keydownEvent, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydownEvent, false);
  }

  changeFunctionality(event) {
    this.setState({
      dashboardContainerOpacity: event.value === 'Search' ? 0.2 : 1,
      selectedFunctionality: event.value,
      showSearch: (event.value === 'Search')
    });
  }
  keydownEvent(event) {
    if (event.keyCode === 27) {
      this.setState({
        selectedFunctionality: 'Summary',
        dashboardContainerOpacity: 1,
        showSearch: false
      });
    }
  }

  changePageLimit(event) {
    this.setState({
      dataIsLoading: true,
      predictions: [],
      pageLimit: parseInt(event.value) / RESULTS_PER_PAGE,
      maxRecords: event.value,
      totalElements: 0
    }, () => this.fetchData());
  }

  fetchData(page = 0) {
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + Base64.encode(config.armUserName + ":" + config.armUserPwd));
    headers.append('Access-Control-Allow-Credentials', true);


    fetch(API_URL + `?isAlerted=true&release=${RELEASE_ID}&size=${RESULTS_PER_PAGE}&page=${page}`, {
      method: 'GET',
      headers: headers,
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(json => this.updateStateData(json.content, page + 1, json.last, json.totalElements));
  }

  searchCustomer(customerId) {
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + Base64.encode(config.armUserName + ":" + config.armUserPwd));
    fetch(API_URL + `?release=${RELEASE_ID}&customer=${customerId}`, {
      method: 'GET',
      headers: headers,
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(function (json) {
        let prediction = null;
        if (json.content !== null && json.content.length) {
          prediction = {
            isDefault: json.content[0].isDefault,
            probability: json.content[0].probability,
            customer: json.content[0].customer
          };
        }
        this.setState({ customerPrediction: prediction });
      }.bind(this));
  }

  updateStateData(data, nextPage, isLastPage, totalElements) {
    let predictions = this.state.predictions;
    data.forEach(function (prediction, index) {
      predictions.push(prediction);
      if (this.state.totalElements === 0) {
        this.setState({
          evaluation: {
            model: {
              foreignId: prediction.modelForeignId,
              notes: prediction.modelNotes
            },
            dataDate: prediction.dataDate,
            predictionDate: prediction.predictionDate,
            formattedSensitivity: prediction.formattedSensitivity,
            formattedProbabilityFalseAlarms: prediction.formattedProbabilityFalseAlarms,
            formattedEfficiency: prediction.formattedEfficiency
          },
          totalElements: totalElements
        });
      }
    }.bind(this));
    this.setState({
      predictions: predictions,
    }, function () {
      this.dashboard.current.updateGraphData();
      if (!isLastPage && (this.state.maxRecords !== 0 && nextPage < this.state.pageLimit)) {
        this.fetchData(nextPage);
      } else {
        this.setState({ dataIsLoading: false });
      }
    });
  }

  render() {
    let loadingProgression = (this.state.predictions.length / this.state.totalElements * 100);
    return (
      <Container>
        {this.state.dataIsLoading &&
          <Container className="progress-bar-container">
            <Container className="progress" style={{ height: '5px' }}>
              <Container className="progress-bar" role="progressbar" style={{ width: loadingProgression + '%' }} aria-valuenow={loadingProgression} aria-valuemin="0" aria-valuemax={100}></Container>
            </Container>
          </Container>
        }
        <Container className="col-lg-6 mx-auto col-md-8 col-sm-12">
          {this.state.showSearch && <CustomerSearch handleSearch={(customerId) => this.searchCustomer(customerId)} prediction={this.state.customerPrediction} />}
        </Container>

        {/* static navbar - top */}
        <Nav className="navbar navbar-expand-lg fixed-top is-white is-dark-text" >
          <Container className="navbar-brand h1 mb-0 text-large font-medium" >
            ARM Dashboard
          </Container>
          <Container className="navbar-nav ml-auto">
            <Container className="user-detail-section">
              <span className="pr-2">Welcome!</span>
              <span className="img-container">
                <img src={UserImg} className="rounded-circle" alt="user" />
              </span>
            </Container>
          </Container>
        </Nav>
        {/* static navbar - bottom */}
        <Nav className="navbar fixed-top nav-secondary is-dark is-light-text">
          <Container className="text-medium">
            <Dropdown
              className="pr-2 custom-dropdown"
              options={DROPDOWN_FUNCTIONALITY_OPTIONS}
              onChange={this.changeFunctionality}
              value={this.state.selectedFunctionality}
              placeholder="Select functionality"
            />
          </Container>
          <Container className="navbar-nav ml-auto">
            <div className='row'>
              <div className='col-6 text-muted mt-2'>Records</div>
              <Dropdown
                className="pr-2 custom-dropdown col-6"
                options={RESULTS_LIMIT_DROPDOWN}
                onChange={this.changePageLimit}
                value={this.state.maxRecords.toString()}
                placeholder="Select a page limit"
              />
            </div>
          </Container>
        </Nav>
        <Dashboard ref={this.dashboard} opacity={this.state.dashboardContainerOpacity} evaluation={this.state.evaluation} predictions={this.state.predictions} />
      </Container>
    );
  }
}

export default App;

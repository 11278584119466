//https://www.fusioncharts.com/dev/maps/spec-sheets/spainprovinces
export const SpainProvinceMapping = {
	'01': '002',
	'02': '001',
	'03': '003',
	'04': '004',
	'33': '005',
	'05': '006',
	'06': '007',
	'07': '008',
	'08': '009',
	'09': '011',
	'10': '012',
	'11': '013',
	'39': '014',
	'12': '015',
	'51': '016',
	'13': '017',
	'14': '018',
	'16': '019',
	'17': '021',
	'18': '022',
	'19': '023',
	'20': '020',
	'21': '024',
	'22': '025',
	'23': '026',
	'15': '027',
	'26': '028',
	'35': '029',
	'24': '030',
	'25': '031',
	'27': '032',
	'28': '033',
	'29': '034',
	'52': '035',
	'30': '036',
	'31': '037',
	'32': '038',
	'34': '039',
	'36': '040',
	'37': '041',
	'40': '043',
	'41': '044',
	'42': '045',
	'43': '046',
	'38': '042',
	'44': '047',
	'45': '048',
	'46': '049',
	'47': '050',
	'48': '010',
	'49': '051',
	'50': '052'
};
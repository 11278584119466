import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Container, SearchBox, Circle, HR } from "./styled-components";

class CustomerSearch extends Component {
	constructor() {
		super();
		this.state = {
		};

		this.customerId = React.createRef();
	}

	getRenderedPrediction(prediction) {
		if (prediction === null) {
			return prediction;
		}
		let color = '#ffc107';
		let status = 'WARNING';
		if (prediction.isRed) {
			color = '#de4721';
			status = 'BAD';
		}
		return (
			<Container className="row">
				<Container className="card grid-card is-card-dark">
					<Container className="card-heading">
						<Container className="is-dark-text-light letter-spacing" >
							{status}
						</Container>
						<Container className="card-heading-brand">
							<Circle viewBox="0 0 50 50">
								<circle
									className="path"
									cx="25"
									cy="25"
									r="20"
									fill={color}
									strokeWidth="1"
								/>
							</Circle>
						</Container>
					</Container>
					<Container className="card-value pt-4 text-medium">
						<div><span className="text-medium">Id</span> <span className="text-info float-right">{prediction.customer.id}</span></div>
						<div><span className="text-medium">Age</span> <span className="text-info float-right">{prediction.customer.age}</span></div>
						<div><span className="text-medium">Unemployed</span> <span className="text-info float-right">{prediction.customer.umployed ? "Yes" : "Not"}</span></div>
						<div><span className="text-medium">Type</span> <span className="text-info float-right">{prediction.customer.freelancer ? "Freelancer" : "Salaried"}</span></div>
						<div><span className="text-medium">Postal Code</span> <span className="text-info float-right">{prediction.customer.postalCode}</span></div>
					</Container>
				</Container>
			</Container>
		);
	}

	render() {
		return (
			<Container id="arm-search-box" className="container-fluid pr-5 pl-5 pt-5 pb-5">
				<Container className="row" style={{ minHeight: "400px" }}>
					<Container className="col-12 is-light-text">
						<Container className="input-group mb-3">
							<SearchBox placeholder="Customer ID" ref={this.customerId} className="form-control" ></SearchBox>
							<Container className="input-group-append">
								<button className="btn btn-outline-secondary" onClick={() => this.props.handleSearch(this.customerId.current.value)} type="button">Search</button>
							</Container>
						</Container>
					</Container>
					<Container className="col-12 is-light-text mb-4">
						{this.getRenderedPrediction(this.props.prediction)}
					</Container>
				</Container>
			</Container>
		);
	}
}

CustomerSearch.propTypes = {
	handleSearch: PropTypes.func.isRequired,
	prediction: PropTypes.object
};

export default CustomerSearch;
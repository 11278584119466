import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Container } from "./styled-components";
import { SpainProvinceMapping } from "./spain-province-mapping";
// fusioncharts
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import Maps from "fusioncharts/fusioncharts.maps";
import SpainProvince from "fusionmaps/maps/es/fusioncharts.spainprovinces";
import ReactFC from "react-fusioncharts";
import "./charts-theme";

ReactFC.fcRoot(FusionCharts, Charts, Maps, SpainProvince);

class Dashboard extends Component {
	constructor() {
		super();
		this.state = {
			provinceData: [],
			predictionByAgeDataSource: {}
		};
	}

	getPredictionByAgeDataSource(predictionByAge) {
		if (Object.keys(predictionByAge).length === 0 && predictionByAge.constructor === Object) {
			return null;
		}
		let badData = [];
		let goodData = [];
		let categories = [];
		for (let [key, value] of Object.entries(predictionByAge)) {
			categories.push({ "label": key, "fontItalic": 0 });
			badData.push({ "value": value.totalBad });
			goodData.push({ "value": value.totalGood });
		}
		let dataSource = {
			"chart": {
				"caption": "Prediction by Age",
				"theme": "ecommerce",
				"divLineThickness": 0,
				"numDivLines": 0,
				"plotToolText": "<b>$label</b><br>$seriesName: <b>$dataValue</b>",
				"paletteColors": "#ffc107, #de4721"
			},
			"categories": [
				{
					"category": categories
				}
			],
			"dataset": [
				{
					"seriesname": "Medium",
					"data": goodData
				},
				{
					"seriesname": "High",
					"data": badData
				}
			]
		};

		return dataSource;
	}

	getPredictionByProvinceGraphData(predictionByPostalCode) {
		if (Object.keys(predictionByPostalCode).length === 0 && predictionByPostalCode.constructor === Object) {
			return [];
		}
		let provinceData = [];
		for (let [key, value] of Object.entries(predictionByPostalCode)) {
			let percentage = Math.round(value.totalBad / (value.totalGood + value.totalBad) * 100);
			provinceData.push({
				id: SpainProvinceMapping[key],
				value: percentage,
				tooltext: `Total Medium ${value.totalGood} {br}Total High: ${value.totalBad}{br}${percentage}%`
			});
		}

		return provinceData;
	}

	getTotalHighByCustomerType(type) {
		switch (type) {
			case 'freelancer':

				return this.props.predictions.filter((prediction) => prediction.customer.freelancer && prediction.isRed).length;
			case 'salaried':
				
				return this.props.predictions.filter((prediction) => !prediction.customer.freelancer && prediction.isRed).length;
			case 'unemployed':
				
				return this.props.predictions.filter((prediction) => prediction.customer.unemployed && prediction.isRed).length;
			default:
				console.error('unknown customer type')
				break;
		}
	}

	getTotalMediumByCustomerType(type) {
		switch (type) {
			case 'freelancer':

				return this.props.predictions.filter((prediction) => prediction.customer.freelancer && !prediction.isRed).length;
			case 'salaried':
				
				return this.props.predictions.filter((prediction) => !prediction.customer.freelancer && !prediction.isRed).length;
			case 'unemployed':
				
				return this.props.predictions.filter((prediction) => prediction.customer.unemployed && !prediction.isRed).length;
			default:
				console.error('unknown customer type')
				break;
		}
	}

	updateGraphData() {
		let predictionByAge = {};
		let predictionByPostalCode = {};
		this.props.predictions.forEach(function (prediction, index) {

			if (!(prediction.customer.age in predictionByAge)) {
				predictionByAge[prediction.customer.age] = {};
				predictionByAge[prediction.customer.age].totalBad = 0;
				predictionByAge[prediction.customer.age].totalGood = 0;
			}
			let provinceCode = prediction.customer.postalCode.toString().substring(0, 2);
			if (!(provinceCode in predictionByPostalCode)) {
				predictionByPostalCode[provinceCode] = {};
				predictionByPostalCode[provinceCode].totalBad = 0;
				predictionByPostalCode[provinceCode].totalGood = 0;
			}
			if (prediction.isRed) {
				predictionByAge[prediction.customer.age].totalBad++;
				predictionByPostalCode[provinceCode].totalBad++;
			} else {
				predictionByAge[prediction.customer.age].totalGood++;
				predictionByPostalCode[provinceCode].totalGood++;
			}

		});
		this.setState({
			provinceData: this.getPredictionByProvinceGraphData(predictionByPostalCode),
			predictionByAgeDataSource: this.getPredictionByAgeDataSource(predictionByAge)
		});
	}
	render() {

		return (
			<Container opacity={this.props.opacity} className="container-fluid pr-5 pl-5 pt-5 pb-5">
				<Container className="row">
					<Container className="col-lg-3 col-sm-6 is-light-text  mb-4">
						<Container className="card grid-card is-card-dark">
							<Container className="card-heading">
								<Container className="is-dark-text-light letter-spacing text-small" >
									Model Used
								</Container>
								<Container className="card-heading-brand">
									<i className="fas fa-cogs text-medium is-dark-text-light" />
								</Container>
							</Container>

							<Container className="card-value pt-4 text-medium">
								<div><span className="text-medium">Model</span> <span className="text-info float-right">{this.props.evaluation.model.foreignId}</span></div>
								<div><span className="text-medium">Data date</span> <span className="text-info float-right">{new Date(this.props.evaluation.dataDate).toLocaleDateString()}</span></div>
								<div><span className="text-medium">Deployed at</span> <span className="text-info float-right">{new Date(this.props.evaluation.predictionDate).toLocaleDateString()}</span></div>
								<div><span className="text-medium">Sensitivity</span> <span className="text-info float-right">{this.props.evaluation.formattedSensitivity}</span></div>
								<div><span className="text-medium">PFA</span> <span className="text-info float-right">{this.props.evaluation.formattedProbabilityFalseAlarms}</span></div>
								<div><span className="text-medium">Efficienciy</span> <span className="text-info float-right">{this.props.evaluation.formattedEfficiency}</span></div>
								<div><span className="text-medium">Notes</span> <span className="text-info float-right">{this.props.evaluation.model.notes}</span></div>
							</Container>
						</Container>
					</Container>
					<Container className="col-lg-9 col-sm-6 is-light-text mb-4">
						<Container className="row">
							<Container className="col-lg-4 col-sm-6 is-light-text mb-4">
								<Container className="card grid-card is-card-dark">
									<Container className="card-heading">
										<Container className="is-dark-text-light letter-spacing text-small">
											Total Clients
					</Container>
										<Container className="card-heading-brand">
											<i className="fas fa-users text-medium is-dark-text-light" />
										</Container>
									</Container>

									<Container className="card-value pt-4 text-x-large">
										{this.props.predictions.length}
									</Container>
								</Container>
							</Container>

							<Container className="col-lg-4 col-sm-6 is-light-text mb-4">
								<Container className="card grid-card is-card-dark">
									<Container className="card-heading">
										<Container className="is-dark-text-light letter-spacing text-small">
											Total Medium
					</Container>
										<Container className="card-heading-brand">
											<strong className="text-large is-dark-text-light" >!</strong>
										</Container>
									</Container>

									<Container className="card-value pt-4 text-x-large">
										{this.props.predictions.filter((prediction) => !prediction.isRed).length}
									</Container>
								</Container>
							</Container>

							<Container className="col-lg-4 col-sm-6 is-light-text mb-4">
								<Container className="card grid-card is-card-dark">
									<Container className="card-heading">
										<Container className="is-dark-text-light letter-spacing text-small">
											Total High
					</Container>
										<Container className="card-heading-brand">
											<strong className="text-large is-dark-text-light" >!!</strong>
										</Container>
									</Container>
									<Container className="card-value pt-4 text-x-large">
										{this.props.predictions.filter((prediction) => prediction.isRed).length}
									</Container>
								</Container>
							</Container>
						</Container>
						<Container className="row donoughts-container">
							<Container className="col-md-12 is-light-text">
								<Container className="card is-card-dark chart-card">
									<Container className="row full-height">
										<Container className="col-sm-4 full height">
											<Container className="chart-container full-height">
												<ReactFC
													{...{
														type: "doughnut2d",
														width: "100%",
														height: "100%",
														dataFormat: "json",
														containerBackgroundOpacity: "0",
														dataSource: {
															chart: {
																caption: "Salaried",
																theme: "ecommerce",
																defaultCenterLabel: `${this.getTotalMediumByCustomerType('salaried')}`,
																paletteColors: "#ffc107, #de4721"
															},
															data: [
																{
																	label: "active",
																	value: `${(this.getTotalMediumByCustomerType('salaried') / (this.getTotalMediumByCustomerType('salaried') + this.getTotalHighByCustomerType('salaried')) * 100)}`
																},
																{
																	label: "inactive",
																	value: `${(this.getTotalHighByCustomerType('salaried') / (this.getTotalMediumByCustomerType('salaried') + this.getTotalHighByCustomerType('salaried')) * 100)}`
																}
															]
														}
													}}
												/>
											</Container>
										</Container>
										<Container className="col-sm-4 full-height border-left border-right">
											<Container className="chart-container full-height">
												<ReactFC
													{...{
														type: "doughnut2d",
														width: "100%",
														height: "100%",
														dataFormat: "json",
														containerBackgroundOpacity: "0",
														dataSource: {
															chart: {
																caption: "Freelancers",
																theme: "ecommerce",
																defaultCenterLabel:  `${this.getTotalMediumByCustomerType('freelancer')}`,
																paletteColors: "#ffc107, #de4721"
															},
															data: [
																{
																	label: "active",
																	value: `${(this.getTotalMediumByCustomerType('freelancer') / (this.getTotalMediumByCustomerType('freelancer') + this.getTotalHighByCustomerType('freelancer')) * 100)}`
																},
																{
																	label: "inactive",
																	value: `${(this.getTotalHighByCustomerType('freelancer') / (this.getTotalMediumByCustomerType('freelancer') + this.getTotalHighByCustomerType('freelancer')) * 100)}`
																}
															]
														}
													}}
												/>
											</Container>
										</Container>
										<Container className="col-sm-4 full-height">
											<Container className="chart-container full-height">
												<ReactFC
													{...{
														type: "doughnut2d",
														width: "100%",
														height: "100%",
														dataFormat: "json",
														containerBackgroundOpacity: "0",
														dataSource: {
															chart: {
																caption: "Unemployed",
																theme: "ecommerce",
																defaultCenterLabel: `${this.getTotalMediumByCustomerType('unemployed')}`,
																paletteColors: "#ffc107, #de4721"
															},
															data: [
																{
																	label: "active",
																	value: `${(this.getTotalMediumByCustomerType('unemployed') / (this.getTotalMediumByCustomerType('unemployed') + this.getTotalHighByCustomerType('unemployed')) * 100)}`
																},
																{
																	label: "inactive",
																	value: `${(this.getTotalHighByCustomerType('unemployed') / (this.getTotalMediumByCustomerType('unemployed') + this.getTotalHighByCustomerType('unemployed')) * 100)}`
																}
															]
														}
													}}
												/>
											</Container>
										</Container>
									</Container>
								</Container>
							</Container>
						</Container>
					</Container>
				</Container>
				<Container className="row" style={{ minHeight: "400px" }}>
					<Container className="col-md-12 mb-4">
						<Container className="card is-card-dark chart-card">
							<Container className="chart-container large full-height">
								<ReactFC
									{...{
										type: "mscolumn2d",
										width: "100%",
										height: "100%",
										dataFormat: "json",
										containerBackgroundOpacity: "0",
										dataEmptyMessage: "Loading Data...",
										dataSource: this.state.predictionByAgeDataSource
									}}
								/>
							</Container>
						</Container>
					</Container>
				</Container>
				<Container className="row" style={{ minHeight: "800px" }}>
					<Container className="col-md-12 mb-4">
						<Container className="card is-card-dark chart-card">
							<Container className="chart-container large full-height">
								<ReactFC
									{...{
										type: "spainprovinces",
										width: "100%",
										height: "100%",
										dataFormat: "json",
										containerBackgroundOpacity: "0",
										dataEmptyMessage: "Loading Data...",
										dataSource: {
											chart: {
												theme: "ecommerce",
												caption: "Percentage of Defaults by Province",
											},
											colorrange: {
												code: "#ffc107",
												minvalue: "0",
												gradient: "1",
												color: [
													{
														minValue: "0",
														maxvalue: "5",
														code: "#ffc107"
													},
													{
														minvalue: "5",
														maxvalue: "100",
														code: "#de4721"
													}
												]
											},
											data: this.state.provinceData
										}
									}}
								/>
							</Container>
						</Container>
					</Container>
				</Container>
			</Container>
		);
	}
}

Dashboard.propTypes = {
	evaluation: PropTypes.object.isRequired,
	opacity: PropTypes.number,
	predictions: PropTypes.array.isRequired
};

export default Dashboard;